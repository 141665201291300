<template>
  <v-container>
    <DashboardHeader icon="mdi-account-heart" title="කැමතිම සාමාජිකයන්" />

    <v-data-table
      :footer-props="$store.state.dataTableFooterProps"
      :headers="headers"
      :items="favourites"
      :loading="loading"
      :options.sync="options"
      :server-items-length="total"
      @click:row="(item) => $router.push(`members/${item.favouriteId}`)"
      class="custom-shadow custom-radius"
      disable-sort
    >
      <template v-slot:item.action="{item}">
        <v-btn :loading="item.loading" @click.stop="remove(item)" color="error" icon>
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </template>
      <template slot="no-data">ලබා ගත හැකි දත්ත නැත</template>
    </v-data-table>

    <ConfirmDialog ref="confirm" />
  </v-container>
</template>

<script>
import { mapMutations } from "vuex";
import { ApiService } from "../services/api.service";
import { CHANGE_LOADING_STATE, SHOW_ALERT } from "../store/mutation-types";
import DashboardHeader from "../components/DashboardHeader";
import ConfirmDialog from "../components/ConfirmDialog";

export default {
  components: {
    DashboardHeader,
    ConfirmDialog
  },

  data: () => ({
    loading: false,
    headers: [
      { text: "සාමාජික අංකය", value: "favouriteId" },
      { text: "ක්‍රියා", value: "action", align: "right" }
    ],
    options: {},
    favourites: [],
    total: 0,
  }),

  async mounted() {
    this.$store.commit(CHANGE_LOADING_STATE, { active: true });
    await this.fetchFavourites();
    this.$store.commit(CHANGE_LOADING_STATE, { active: false });
  },

  watch: {
    options: {
      handler: function () {
        this.fetchFavourites();
      },
      deep: true,
    }
  },

  methods: {
    ...mapMutations({ showAlert: SHOW_ALERT }),

    // Fetch favourite list
    fetchFavourites: async function () {
      this.loading = true;

      const { page, itemsPerPage } = this.options
      const pageOptions = itemsPerPage != -1 ? { perPage: itemsPerPage, page: page } : {};

      try {
        const url = "customers/get-favourites";
        const params = { params: { ...pageOptions } };
        const response = await ApiService.get(url, params);
        this.favourites = response.data.data;
        this.total = response.data.meta.total;
      } catch (error) {
        console.log(error);
      }
      this.loading = false;
    },

    // Remove from favourite list
    remove: async function (item) {
      if (await this.$refs.confirm.open('ඉවත් කරන්න', 'ඔබට විශ්වාසද?', { color: 'error' })) {
        this.loading = true;
        try {
          const url = "customers/add-to-favourite";
          const data = { favPersonId: item.favouriteId };
          const response = await ApiService.post(url, data);
          const index = this.favourites.indexOf(item);
          this.favourites.splice(index, 1);
          this.showAlert({ message: "කැමතිම ලැයිස්තුවෙන් ඉවත් කරන ලදි", type: "success" });
        } catch (error) {
          console.log(error);
        }
        this.loading = false;
      }
    }
  }
}
</script>